import React from "react"
import moment from "moment"

export default function dateFormat(date) {
  return <div>{moment(date).format("DD MMM YYYY")} </div>
}

export const dateFormatStringToDate = date => {
  var date = moment(date).format("DD MMM YYYY, h:mm a")
  return date
}

export const formatStringToDate = date => {
  var date = moment(date).format("yyyy-MM-DD")
  return date
}

export const currentYear = () => {
  return new Date().getFullYear()
}
