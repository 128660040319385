import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_MS_USER,
  LOGIN_MS_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from "./actionTypes"
import error_handler_helper from "helpers/error_handler_helper"
export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const loginMsUser = (token, history) => {
  return {
    type: LOGIN_MS_USER,
    payload: { token, history },
  }
}

export const loginMsSuccess = user => {
  return {
    type: LOGIN_MS_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  // console.log(error)
  return {
    type: API_ERROR,
    payload: error_handler_helper(error),
  }
}

export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history },
  }
}
