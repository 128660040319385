import React, { useState, useEffect } from "react"
import { Col, Label, Row } from "reactstrap"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const SearchFilter = props => {
  const { setFilterDates } = props
  // const [selectDate, setSelectDate] = useState();
  // const dateChange = (date) => {
  //     setSelectDate(date)
  // };
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  useEffect(() => {
    // console.log(dateRange)
    if (dateRange) {
      // console.log(startD)
      setFilterDates(dateRange)
    } else {
      setFilterDates()
    }
  }, [dateRange])
  return (
    <React.Fragment>
      <Col md={6}>
        <div
          id="datepicker1"
          style={{
            alignItems: "right",
            float: "right",
            textAlign: "right",
            width: "70%",
          }}
        >
          <Row>
            <Col>
              {" "}
              <Label className="my-2">Filter by Date:</Label>
            </Col>
            <Col>
              <DatePicker
                placeholderText="start date - end date"
                selectsRange={true}
                startDate={startDate}
                className="form-control"
                // dateFormat="yyyy/MM/dd h:mm aa"
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                }}
                isClearable={true}
              />
            </Col>
          </Row>
        </div>
      </Col>
      {/* <Col xxl={1} lg={2}>
        <div className="mb-3 mb-xxl-0">
          <button type="button" className="btn btn-soft-secondary w-100">
            <i className="mdi mdi-filter-outline align-middle"></i> Filter
          </button>
        </div>
      </Col> */}
    </React.Fragment>
  )
}
export default SearchFilter
